import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import MenuPage from "./pages/MenuPage";  
import NotFoundPage from "./pages/NotFoundPage"
import CheckoutPage from "./pages/CheckoutPage"
import OrdenPage from "./pages/OrdenPage"
import BuscarOrden from "./pages/BuscarOrden"
import PaymentPage from "./components/PaymentPage";
import PoliticasPage from "./pages/PoliticasPage";
import TerminosPage from "./pages/TerminosPage";
import PoliticaCookiesPage from "./pages/PoliticaCookiesPage";

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/privacy" element={<PoliticasPage/>}/>
          <Route path="/terms" element={<TerminosPage/>}/>
          <Route path="/cookiesPolicy" element={<PoliticaCookiesPage/>}/>
          <Route path="/:id" element={<MenuPage />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/:id/checkout" element={<CheckoutPage />} />
          <Route path="/:id/orden/:ordenid" element={<OrdenPage />} />
          <Route path="/:id/orden" element={<BuscarOrden />} />
          <Route path="/stripe" element={<PaymentPage />} />
        </Routes>
    </Router>
  );
}

export default App;
