import React from "react";
import styles from "../../assets/css/landingpage/pricing.module.css";


const PricingSection = () => {
    return (
<section className={styles.pricingSection} id="precios">
    <h2>Planes y Precios</h2>
    <div className={styles.pricingContainer}>
        <div className={styles.planCard}>
            <h5>Basico</h5>
            <p className={styles.cardPrice}>
                $499   <span className={styles.billingPeriod}>x Mes</span>
            </p>
            <button 
                className={`${styles.btn} ${styles.btnPrimary}`}
                onClick={() => window.open("https://mimenu.pallevar.me/signup", "_blank")}
                >
                    Iniciar Prueba
                </button>
            <ul>
                <li><i className="fas fa-check-circle"></i> Gestión de Pedidos</li>
                <li><i className="fas fa-check-circle"></i> Panel Administrativo</li>
                <li><i className="fas fa-check-circle"></i> Menú Interactivo</li>
                <li><i className="fas fa-check-circle"></i> Soporte por Email</li>
                <li><i className="fas fa-check-circle"></i> Reportes</li>
                <li><i className="fas fa-check-circle"></i> Productos Limitados</li>
                <li><i className="fas fa-check-circle"></i> Acceso a 2 Usuario</li>
                <li><i className="fas fa-check-circle"></i> Prueba Gratuita de 3 Meses</li>
                <li><i className="fas fa-check-circle"></i> Comanda Digital</li>
                <li><i className="fas fa-check-circle"></i> Mail al Cliente</li>
                <li><i className="fas fa-check-circle"></i> 1 Usuario Administrativo</li>
                <li><i className="fas fa-check-circle"></i> 1 Menú por Restaurante</li>
            </ul>
            

        </div>
        <div className={styles.planCard}>
            <h5>Professional</h5>
            <p className={styles.cardPrice}>
                $999   <span className={styles.billingPeriod}>x Mes</span>
            </p>
            <button 
                className={`${styles.btn} ${styles.btnPrimary}`}
                onClick={() => window.open("https://mimenu.pallevar.me/signup", "_blank")}
                >
                Iniciar Prueba
            </button>
            <ul>
                <li><i className="fas fa-check-circle"></i> Gestión de Pedidos</li>
                <li><i className="fas fa-check-circle"></i> Panel Administrativo</li>
                <li><i className="fas fa-check-circle"></i> Menú Interactivo</li>
                <li><i className="fas fa-check-circle"></i> Soporte Email y Chat</li>
                <li><i className="fas fa-check-circle"></i> Reportes Avanzados</li>
                <li><i className="fas fa-check-circle"></i> Productos Moderados</li>
                <li><i className="fas fa-check-circle"></i> Acceso a 3 Usuarios</li>
                <li><i className="fas fa-check-circle"></i> Prueba Gratuita de 3 Meses</li>
                <li><i className="fas fa-check-circle"></i> Comanda Digital</li>
                <li><i className="fas fa-check-circle"></i> SMS al Cliente</li>
                <li><i className="fas fa-check-circle"></i> 3 Usuarios Administrativos</li>
                <li><i className="fas fa-check-circle"></i> Hasta 2 Menús por Restaurante</li>
                <li><i className="fas fa-check-circle"></i> Impresión Automática de Comandas</li>
                <li><i className="fas fa-check-circle"></i> Gestión de Inventarios (Básica)</li>
                <li><i className="fas fa-check-circle"></i> Centralización de Pedidos de Múltiples Plataformas</li>
                <li><i className="fas fa-check-circle"></i> Personalización Avanzada del Menú</li>
                <li><i className="fas fa-check-circle"></i> Integración con Puntos de Venta (Configuración Inicial)</li>
                <li><i className="fas fa-check-circle"></i> Gestión de Hasta 2 Sucursales</li>
                <li><i className="fas fa-check-circle"></i> Historial de Pedidos Detallado</li>
            </ul>
 
        </div>
        <div className={styles.planCard}>
            <h5>Premium</h5>
            <p className={styles.cardPrice}>
                $1,499   <span className={styles.billingPeriod}>x Mes</span>
            </p>
            <button 
                className={`${styles.btn} ${styles.btnPrimary}`}
                onClick={() => window.open("https://mimenu.pallevar.me/signup", "_blank")}
                >
                    Iniciar Prueba
                </button>
            <ul>
                <li><i className="fas fa-check-circle"></i> Todo lo incluido en el Plan Professional</li>
                <li><i className="fas fa-check-circle"></i> Acceso para 5 Usuarios Administrativos</li>
                <li><i className="fas fa-check-circle"></i> Multi-Menú: Hasta 5 Menús por Restaurante</li>
                <li><i className="fas fa-check-circle"></i> Gestión Avanzada de Inventarios</li>
                <li><i className="fas fa-check-circle"></i> Integración Total con Puntos de Venta</li>
                <li><i className="fas fa-check-circle"></i> Reportes Personalizados</li>
                <li><i className="fas fa-check-circle"></i> Analítica Avanzada de Ventas</li>
                <li><i className="fas fa-check-circle"></i> Integración con Plataformas de Entrega (Uber Eats, Didi Food, etc.)</li>
                <li><i className="fas fa-check-circle"></i> Soporte Premium por Chat y Email</li>
                <li><i className="fas fa-check-circle"></i> Gestión de Hasta 5 Sucursales</li>
                <li><i className="fas fa-check-circle"></i> Comandas Automáticas por Área (Cocina, Bar, etc.)</li>
                <li><i className="fas fa-check-circle"></i> Personalización Avanzada de Notificaciones</li>
                <li><i className="fas fa-check-circle"></i> Programación de Promociones y Ofertas</li>
                <li><i className="fas fa-check-circle"></i> Historial de Pedidos por Cliente</li>
            </ul>
        </div>

    </div>
</section>
        );
    };
    
    export default PricingSection;
    