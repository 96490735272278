import React from "react";
import NavBar from "../components/landingpage/MenuNavbar";
import FooterSection from "../components/landingpage/FooterSection";
import styles from "../assets/css/privacy.module.css";

const PoliticaCookiesPage  = () => {    
    return (
        <>
            <NavBar />
            <section className={styles.main}>
            <div className={styles.container}>
            <h1>Política de Cookies</h1>
                <p>
                    En <strong>Pallevar</strong>, utilizamos cookies para mejorar la experiencia de los usuarios en nuestra plataforma.
                    Esta política explica qué son las cookies, cómo las usamos y cómo puedes gestionarlas.
                </p>

                <h2>1. ¿Qué son las cookies?</h2>
                <p>
                    Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo (ordenador, tablet o smartphone) 
                    cuando visitas un sitio web. Estas permiten que el sitio web recuerde tus acciones y preferencias 
                    durante un período de tiempo, mejorando tu experiencia de navegación.
                </p>

                <h2>2. ¿Qué tipos de cookies utilizamos?</h2>
                <p>
                    En nuestra plataforma, utilizamos los siguientes tipos de cookies:
                </p>
                <ul>
                    <li>
                        <strong>Cookies necesarias:</strong> Estas cookies son esenciales para el funcionamiento de la plataforma y no 
                        pueden ser desactivadas. Incluyen funcionalidades como el inicio de sesión y la gestión de pedidos.
                    </li>
                    <li>
                        <strong>Cookies de rendimiento:</strong> Estas cookies recopilan información sobre cómo los usuarios interactúan 
                        con nuestra plataforma para ayudarnos a mejorar su funcionamiento.
                    </li>
                    <li>
                        <strong>Cookies de funcionalidad:</strong> Estas cookies permiten que nuestra plataforma recuerde tus 
                        preferencias, como el idioma seleccionado.
                    </li>
                    <li>
                        <strong>Cookies de terceros:</strong> Utilizamos cookies de terceros, como Google Analytics, para 
                        analizar el tráfico y mejorar la experiencia del usuario.
                    </li>
                </ul>

                <h2>3. ¿Cómo gestionar las cookies?</h2>
                <p>
                    Puedes gestionar y desactivar las cookies directamente desde la configuración de tu navegador. A continuación, 
                    encontrarás enlaces a las guías de configuración de los navegadores más populares:
                </p>
                <ul>
                    <li><a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noopener noreferrer">Google Chrome</a></li>
                    <li><a href="https://support.mozilla.org/es/kb/Deshabilitar%20cookies" target="_blank" rel="noopener noreferrer">Mozilla Firefox</a></li>
                    <li><a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">Safari</a></li>
                    <li><a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener noreferrer">Internet Explorer</a></li>
                    <li><a href="https://www.microsoft.com/es-es/edge" target="_blank" rel="noopener noreferrer">Microsoft Edge</a></li>
                </ul>

                <h2>4. Cambios en esta política</h2>
                <p>
                    Podemos actualizar esta política de cookies en cualquier momento para reflejar cambios en nuestras prácticas 
                    o en la normativa aplicable. Te recomendamos revisar esta página periódicamente para estar informado.
                </p>

                <h2>5. Contacto</h2>
                <p>
                    Si tienes alguna pregunta sobre nuestra política de cookies, no dudes en ponerte en contacto con nosotros en <strong>pallevar.me@gmail.com</strong>.
                </p>
                </div>
            </section>
            <FooterSection />
        </>
    );
};

export default PoliticaCookiesPage ;
