import React from "react";
import NavBar from "../components/landingpage/MenuNavbar";
import FooterSection from "../components/landingpage/FooterSection";
import styles from "../assets/css/privacy.module.css";

const PoliticasPage = () => {    
    return (
        <>
            <NavBar />
            <section className={styles.main}>
            <div className={styles.container}>
            <h1>Políticas de Privacidad</h1>
                <p>
                    En <strong>Pallevar</strong>, nos comprometemos a proteger tu privacidad y garantizar que tu información personal se maneje de forma segura y responsable. A continuación, detallamos cómo recopilamos, usamos y protegemos tus datos.
                </p>
                <h2>1. Información que recopilamos</h2>
                <p>
                    Podemos recopilar la siguiente información cuando interactúas con nuestra plataforma:
                </p>
                <ul>
                    <li>Tu nombre y correo electrónico al registrarte o realizar un pedido.</li>
                    <li>Detalles de los pedidos realizados a través de nuestra plataforma.</li>
                    <li>Información sobre tu dispositivo, como la dirección IP y el navegador que utilizas.</li>
                </ul>
                
                <h2>2. Procesamiento de pagos</h2>
                <p>
                    Los pagos realizados en nuestra plataforma son procesados por <strong>Stripe</strong>, un proveedor de servicios de pago seguro. 
                    No almacenamos ni manejamos directamente datos sensibles como los números de tarjeta de crédito o débito. Stripe cumple con los estándares de seguridad de la industria, como el PCI DSS.
                </p>
                <p>
                    Para más información, consulta la <a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer">Política de Privacidad de Stripe</a>.
                </p>

                <h2>3. Uso de tu información</h2>
                <p>
                    Usamos tu información para los siguientes fines:
                </p>
                <ul>
                    <li>Procesar y gestionar tus pedidos.</li>
                    <li>Mejorar nuestra plataforma y los servicios que ofrecemos.</li>
                    <li>Comunicarnos contigo respecto a tu cuenta o soporte técnico.</li>
                </ul>

                <h2>4. Protección de datos</h2>
                <p>
                    Implementamos medidas técnicas y organizativas para proteger tu información personal contra acceso no autorizado, pérdida o divulgación.
                </p>

                <h2>5. Cambios a esta política</h2>
                <p>
                    Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Cualquier cambio será publicado en esta página con una fecha de revisión actualizada.
                </p>

                <h2>6. Contacto</h2>
                <p>
                    Si tienes alguna pregunta o inquietud sobre nuestras políticas de privacidad, puedes ponerte en contacto con nosotros en <strong>pallevar.me@gmail.com</strong>.
                </p>
                </div>
            </section>
            <FooterSection />
        </>
    );
};

export default PoliticasPage;
